import { Disclosure, Transition } from '@headlessui/react'
import { useSearchParams } from 'react-router-dom'
import slugify from 'slugify'

interface Props {
  title?: string | undefined | null
  description?: string | undefined | null
  content?: any
  isOpen: boolean
  handleToggle: (isOpen: boolean) => void
  backgroundColor?: string | null
}

export const Accordion: React.FC<Props> = ({ title, content, description, isOpen, handleToggle, backgroundColor }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const subCategory = searchParams.get('sous_categorie')
  const queryCategory = searchParams.get('categorie')
  const queryService = searchParams.get('service')

  const slug = slugify(title ?? '', { lower: true })

  const decodedSubCategory = decodeURIComponent(subCategory || '')
  const parsedSubCategory = decodedSubCategory?.split(',')

  const handleClick = () => {
    let params = {}

    if (queryCategory) {
      params = { ...params, categorie: queryCategory }
    }

    if (queryService) {
      params = { ...params, service: queryService }
    }

    if (isOpen) {
      const index = parsedSubCategory?.findIndex((item) => item === slug)
      parsedSubCategory?.splice(index, 1)
      params = { ...params, sous_categorie: parsedSubCategory }
      handleToggle(false)
    } else {
      params = { ...params, sous_categorie: slug }
      handleToggle(true)

      const element = document.getElementById(`sub_category_${title}`)
      if (element) {
        const scroll = setTimeout(() => {
          element.scrollIntoView(true)
          clearTimeout(scroll)
        }, 10)
      }
    }

    setSearchParams(params)
  }

  return (
    <div id={`sub_category_${title}`} style={{ scrollMarginTop: 90 }}>
      <Disclosure defaultOpen={isOpen} key={`sub_category_${title}_${isOpen}`}>
        <>
          <Disclosure.Button onClick={handleClick} className={`w-full sticky top-[95px] z-20`}>
            <div className="text-center font-saaFD px-[23px]">
              <div
                style={{ backgroundColor: backgroundColor ?? '' }}
                className="border-primary py-[10px] border-y relative bg-white -mb-[1px]"
              >
                <p translate="no" className="text-[17px] uppercase">
                  {title}
                </p>
              </div>
            </div>
          </Disclosure.Button>

          <Transition
            enter="transition duration-500 ease-out"
            enterFrom="transform  opacity-0"
            enterTo="transform  opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform  opacity-0"
          >
            <Disclosure.Panel className="w-full">
              <div className="relative text-center">
                {description && (
                  <div className="px-[30px]">
                    <p className="py-[20px] px-[30px] font-prestige text-[11px] -tracking-[0.35px]">{description}</p>
                  </div>
                )}

                {content}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      </Disclosure>
    </div>
  )
}
